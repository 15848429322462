<template>
  <div class="history">
    <p v-if="dates.loading">Laddar datum...</p>
    <p v-if="dates.error">{{ dates.error }}</p>
    <select v-if="!dates.loading && !dates.error" v-model="selectedDate" class="select" :disabled="data.loading">
      <option :value="null" disabled>Välj datum...</option>
      <option v-for="date in dates.list" :key="date" :value="date">{{ formatDate(date) }}</option>
    </select>
    <p v-if="data.error">{{ data.error }}</p>
    <canvas width="430" height="330" class="canvas"></canvas>
    <div class="table-scroller" v-if="data.value">
      <table class="table">
        <thead>
          <tr>
            <td></td>
            <th v-for="dataset, i in data.value.datasets" :key="i" scope="col" :style="{ 'border-color': dataset.borderColor }" class="subject-name">{{ dataset.label }}</th>
            <th scope="col" class="subject-name">Totalt</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="label, i in data.value.labels" :key="i">
            <th scope="row" class="hour-label">{{ label }}</th>
            <td v-for="dataset, j in data.value.datasets" :key="j">{{ dataset.data[i] }}</td>
            <td>{{ formatNumber(totalForHour(i)) }}</td>
          </tr>
          <tr>
            <th scope="row">Totalt</th>
            <td v-for="dataset, j in data.value.datasets" :key="j">{{ formatNumber(totalForSubject(j)) }}</td>
            <td>{{ formatNumber(total()) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import api from '../api.js'
import { Chart, registerables } from 'chart.js'

Chart.register(...registerables)

export default {
  name: 'History',
  props: {
    company: Object
  },
  data() {
    return {
      chart: null,
      selectedDate: null,
      dates: {
        list: [],
        loading: true,
        error: null
      },
      data: {
        value: null,
        loading: false,
        error: null
      }
    }
  },
  async mounted() {
    await this.loadDates()
  },
  unmounted() {
    if (this.chart !== null) {
      this.chart.destroy()
      this.chart = null
    }
  },
  watch: {
    selectedDate(date) {
      if (date !== null) {
        this.loadData(date)
      }
    }
  },
  methods: {
    formatDate(date) {
      return date.split('T')[0]
    },
    async loadDates() {
      this.dates.loading = true
      this.dates.error = null

      let res = await api.send('GET', `/history/${this.company.slug}`)

      if (res.success) {
        this.dates.list = res.result
      } else {
        this.dates.error = res.errorMessage
      }

      this.dates.loading = false
    },
    async loadData(date) {
      this.data.loading = true
      this.data.value = null
      this.data.error = null

      let res = await api.send('GET', `/history/${this.company.slug}/${this.formatDate(date)}`)

      if (res.success) {
        if (this.chart !== null) {
          this.chart.destroy()
        }

        this.data.value = res.result

        this.chart = new Chart(
          this.$el.getElementsByTagName('canvas')[0].getContext('2d'), 
          {
            type: 'line',
            data: this.data.value,
            options: {
              plugins: {
                legend: {
                  labels: {
                    color: 'white'
                  }
                },
                tooltip: {
                  mode: 'index'
                },

              },
              datasets: {
                line: {
                  tension: 0.2
                }
              },
              scales: {
                y: {
                  ticks: {
                    color: 'white',
                    fontSize: 12
                  },
                  suggestedMin: 0
                },
                x: {
                  ticks: {
                    color: 'white',
                    fontSize: 12
                  }
                }
              },
              animation: {
                duration: 0
              }
            }
          }
        )
      } else {
        this.data.error = res.errorMessage
      }

      this.data.loading = false
    },
    totalForHour(index) {
      return this.data.value.datasets.reduce((prev, ds) => prev + ds.data[index], 0)
    },
    totalForSubject(index) {
      return this.data.value.datasets[index].data.reduce((prev, d) => prev + d, 0)
    },
    total() {
      return this.data.value.datasets.reduce((prev, ds) => prev + ds.data.reduce((_prev, _d) => _prev + _d, 0), 0)
    },
    formatNumber(n) {
      return n.toLocaleString('sv-SE')
    }
  },
  computed: {
    colWidth() {
      return (100 / (this.data.value.datasets.length + 2)).toFixed(4) + '%'
    }
  }
}
</script>

<style lang="scss" scoped>
  .history {
    margin: 0 10px 10px 10px;
  }

  .select {
    width: 100%;
    padding: 3px;
    display: block;
    margin-bottom: 10px;
  }

  .table-scroller {
    overflow: auto hidden;
    margin-top: 15px;
  }

  .table {
    font-size: 12pt;
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;

    td, th {
      padding: 2px 5px;
      text-align: left;
    }

    tr {
      border-bottom: #333333 solid 2px;
    }
  }

  .subject-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: white solid 3px;
  }

  .hour-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: 400px) {
    .table {
      font-size: 4vw;

      td, th {
        padding: 0.5vw 1.25vw;
      }
    }
  }
</style>
