import { createRouter, createWebHistory } from 'vue-router'

import Home from '../views/Home.vue'
import Live from '../views/Live.vue'
import History from '../views/History.vue'

const routes = [
  {
    path: '/:company?',
    name: 'Home',
    component: Home,
    children: [
      {
        path: 'live',
        name: 'Live',
        component: Live
      },
      {
        path: 'historik',
        name: 'History',
        component: History
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
