<template>
  <nav class="company-selector">
    <div v-if="loading" class="loading">
      <Loader class="loader" />
    </div>
    <ul v-if="!loading && !error" class="list">
      <li class="list-item" v-for="company in companies" :key="company.id">
        <router-link :to="{ name: 'Home', params: { company: company.slug } }" class="link">
          <img :src="`/${company.slug}.svg`" :alt="company.name" class="logo">
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
  import store from '../store'
  import Loader from './Loader.vue'

  export default {
    name: 'CompanySelector',
    components: {
      Loader
    },
    computed: {
      companies() {
        return store.state.companies.companies
      },
      loading() {
        return store.state.companies.loading
      },
      error() {
        return store.state.companies.error
      }
    }
  }
</script>

<style lang="scss" scoped>
  .company-selector {
    background-color: #111111;
  }

  .loading {
    padding: 10px;
    display: flex;
    justify-content: center;
  }

  .loader {
    width: 35px;
    height: 35px;
  }

  .list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
  }

  .list-item {
    flex: 1;
  }

  .link {
    display: block;
    padding: 10px 0;

    &.router-link-active {
      background-color: #333333;
    }
  }

  .logo {
    display: block;
    height: 35px;
    margin: 0 auto;
  }
</style>
