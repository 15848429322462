<template>
  <div class="logout">
    <a href="#" class="link" @click.prevent="logout">&laquo; Logga ut</a>
  </div>
</template>

<script>
  import store from '../store'

  export default {
    name: 'LogoutLink',
    methods: {
      logout() {
        store.dispatch('logout')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .logout {
    text-align: center;
    padding: 10px;
    background-color: #111111;
  }

  .link {
    color: #ffffff;
    text-decoration: none;
  }
</style>
