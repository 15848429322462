<template>
  <div class="home">
    <div class="nav" v-if="company">
      <router-link :to="{ name: 'Live', params: { company: $route.params.company } }" class="link">Live</router-link>
      <router-link :to="{ name: 'History', params: { company: $route.params.company } }" class="link">Historik</router-link>
    </div>
    <router-view v-if="company" :company="company"/>
  </div>
</template>

<script>
import store from '../store'

export default {
  name: 'Home',
  store,
  components: {
    
  },
  computed: {
    company() {
      let companies = store.state.companies.companies.filter(c => c.slug === this.$route.params.company)

      if (companies.length > 0) {
        return companies[0]
      } else {
        return null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .home {
    background-color: #333333;
  }

  .nav {
    display: flex;
  }

  .link {
    flex: 1;
    text-align: center;
    color: #cccccc;
    text-decoration: none;
    padding: 10px 0;

    &.router-link-active {
      text-decoration: underline;
      color: #ffffff;
    }
  }
</style>
