<template>
  <div class="login">
    <form class="form" @submit.prevent="submit">
      <label class="input-group">
        <span>Lösenord</span>
        <input type="password" class="input" v-model="password">
      </label>
      <input type="submit" :value="loading ? 'Loggar in...' : 'Logga in'" class="submit" :disabled="loading">
    </form>
  </div>
</template>

<script>
  import store from '../store'

  export default {
    name: 'LoginForm',
    store,
    data() {
      return {
        password: ''
      }
    },
    methods: {
      submit() {
        store.dispatch('login', { password: this.password })
      }
    },
    computed: {
      loading() {
        return store.state.auth.loading
      },
      error() {
        return store.state.auth.error
      }
    }
  }
</script>

<style lang="scss" scoped>
  .form {
    padding: 20px;
  }

  .input {
    width: 100%;
    display: block;
  }

  .submit {
    display: block;
    margin-top: 10px;
  }
</style>
