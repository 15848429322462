<template>
  <div v-if="loggedIn" class="main">
    <LogoutLink />
    <CompanySelector />
    <router-view />
  </div>
  <div v-if="!loggedIn" class="main">
    <LoginForm />
  </div>
</template>

<script>
  import store from './store'
  import ws from './ws.js'

  import LoginForm from './components/LoginForm.vue'
  import LogoutLink from './components/LogoutLink.vue'
  import CompanySelector from './components/CompanySelector.vue'

  export default {
    name: 'App',
    store,
    components: {
      LoginForm,
      LogoutLink,
      CompanySelector
    },
    computed: {
      loggedIn() {
        return store.state.auth.loggedIn
      }
    },
    watch: {
      loggedIn(val) {
        if (val) {
          store.dispatch('loadCompanies')
          ws.connect()
        } else {
          ws.disconnect()
        }
      }
    }
  }
</script>

<style lang="scss">
  @use 'modern-css-reset';
  @import '~bootstrap-icons/font/bootstrap-icons.css';

  html, body, #app {
    height: 100%;
    background-color: #111111;
    color: #ffffff;
  }

  body, #app, input, button, select {
    font-family: 'Heebo', sans-serif;
    font-size: 12pt;
    font-weight: 400;
  }

  .main {
    max-width: 450px;
    margin: 0 auto;
    background-color: #333333;
    height: 100%;
  }
</style>
