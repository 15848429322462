export default {
  state() {
    return {
      devices: []
    }
  },
  mutations: {
    updateDevice(state, payload) {
      if (payload.device !== undefined) {
        for (var i=0; i<state.devices.length; i++) {
          if (state.devices[i].deviceId === payload.device.deviceId) {
            state.devices[i].online = payload.device.online
            state.devices[i].batteryChargeLevel = payload.device.batteryChargeLevel
            state.devices[i].batteryState = payload.device.batteryState
            state.devices[i].batteryPowerSource = payload.device.batterySource
            break
          }
        }
      }
    },
    setDevices(state, payload) {
      if (payload.devices !== undefined) {
        state.devices = payload.devices
      }
    }
  }
}
