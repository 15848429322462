<template>
  <div class="device">
    <div class="top" @click="expand">
      <div class="left" :class="{ online: device.online }">
        <span v-if="!device.online" class="bi bi-x-circle-fill offline"></span>
        <span v-if="device.online" class="bi bi-check-circle-fill online"></span>
        <span>Device {{ device.deviceId }}</span>
      </div>
      <div class="right" :class="{ 'battery-low': batteryLow }" v-if="batteryInfoAvailable">
        <span>{{ batteryCharge }}</span>
        <span v-if="device.online" :class="'bi bi-' + batteryIcon"></span>
      </div>
    </div>
    <div class="controls" v-if="expanded">
      <a href="#" class="control-link" @click.prevent="getActivationCode">Hämta aktiveringskod</a>
      <a href="#" class="control-link right" @click.prevent="collapse">Stäng</a>
    </div>
  </div>
</template>

<script>
  const BATTERY_STATE_CHARGING = 1

  import api from '../api.js'

  export default {
    name: 'Device',
    props: {
      device: Object
    },
    data() {
      return {
        expanded: false
      }
    },
    computed: {
      batteryInfoAvailable() {
        return this.device.batteryChargeLevel !== null
      },
      batteryCharge() {
        return Math.round(this.device.batteryChargeLevel * 100) + '%'
      },
      batteryIcon() {
        if (this.device.batteryState === BATTERY_STATE_CHARGING) {
          return 'battery-charging'
        } else if (this.device.batteryChargeLevel > 0.5) {
          return 'battery-full'
        } else if (this.device.batteryChargeLevel > 0.15) {
          return 'battery-half'
        } else {
          return 'battery'
        }
      },
      batteryLow() {
        return this.device.batteryState !== BATTERY_STATE_CHARGING && this.device.batteryChargeLevel <= 0.15
      }
    },
    methods: {
      expand() {
        this.expanded = true
      },
      collapse() {
        this.expanded = false
      },
      async getActivationCode() {
        let res = await api.send('POST', `/devices/${this.device.deviceId}/activation-codes`)

        if (res.success) {
          alert(`OBS! Om enhet ${this.device.deviceId} redan är aktiverad, kommer den inaktiveras när denna aktiveringskod används på en ny enhet.\n\nAktiveringskod: ${res.result.code}\nGiltighetstid: 15 minuter`)
        } else {
          alert(`Kunde inte hämta aktiveringskoden.\nSe konsollen för mer information.\n\n${res.errorMessage}`)
          console.log(res)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .device {
    margin-top: 10px;
    background-color: #111111;
  }

  .top {
    padding: 10px;
    display: flex;
    color: #aaaaaa;
  }

  .left {
    flex: 1;

    .bi {
      margin-right: 5px;
    }
  }

  .right {
    text-align: right;

    &.battery-low {
      color: #da5c5c;
    }

    .bi {
      margin-left: 5px;
      font-size: 1.3em;
      line-height: 0;
      vertical-align: middle;
    }
  }

  .online {
    color: #66b04c;
  }

  .controls {
    padding: 10px;
  }

  .control-link {
    color: #aaaaaa;

    &.right {
      float: right;
    }
  }
</style>
