export default {
  state() {
    return {
      events: []
    }
  },
  mutations: {
    addEvent(state, payload) {
      if (payload.event !== undefined) {
        state.events.push(payload.event)
      }
    },
    setEvents(state, payload) {
      if (payload.events !== undefined) {
        state.events = payload.events
      }
    }
  }
}