<template>
  <div class="live">
    <Subject v-for="subject in subjects" :key="subject.id" :subject="subject" :company="company" />
    <Device v-for="device in devices" :key="device.id" :device="device" />
  </div>
</template>

<script>
import store from '../store'
import Subject from '../components/Subject.vue'
import Device from '../components/Device.vue'

export default {
  name: 'Live',
  components: {
    Subject,
    Device
  },
  props: {
    company: Object
  },
  computed: {
    subjects() {
      return store.state.subjects.subjects.filter(s => s.companyId === this.company.id).sort((a, b) => a.weight - b.weight)
    },
    devices() {
      return store.state.devices.devices.filter(d => d.companyId === this.company.id).sort((a, b) => a.id - b.id)
    }
  }
}
</script>

<style lang="scss" scoped>
  .live {
    padding: 0 10px 10px 10px;
    margin-top: -10px;
  }
</style>
