<template>
  <div>
    <teleport to="body">
      <div class="cover" v-if="show" @click="cancel">
        <div class="modal" @click.stop>
          <div class="header">
            <img class="header-logo" :src="`/${company.slug}.svg`" :alt="company.name" width="50" height="50">
            <h1 class="header-title">{{ subject.name }}</h1>
          </div>
          <div class="body">
            <form @submit.prevent="preSaveTargets" novalidate v-if="!inPreSave">
              <fieldset class="fieldset">
                <legend>Effektivitet</legend>
                <label class="label">
                  <span>Mätfönster</span>
                  <input class="targets-input" type="number" v-model="targets.window">
                </label>
              </fieldset>

              <fieldset class="fieldset">
                <legend>Mål, snittsällskap</legend>
                <label class="label">
                  <span>Positivt &#128640;</span>
                  <input class="targets-input" type="number" v-model="targets.count.positive">
                </label>
                <label class="label">
                  <span>Neutralt &#128528;</span>
                  <input class="targets-input" type="number" v-model="targets.count.neutral">
                </label>
              </fieldset>

              <fieldset class="fieldset">
                <legend>Mål, snittid</legend>
                <label class="label">
                  <span>Positivt &#128640;</span>
                  <input class="targets-input" type="number" v-model="targets.interval.positive">
                </label>
                <label class="label">
                  <span>Neutralt &#128528;</span>
                  <input class="targets-input" type="number" v-model="targets.interval.neutral">
                </label>
              </fieldset>

              <div class="buttons">
                <button type="submit" class="button" @click="preSave"><i class="bi bi-save"></i> Spara</button>
                <button type="button" class="button" @click="cancel"><i class="bi bi-x-lg"></i> Avbryt</button>
              </div>
            </form>
            <div class="pre-save" v-if="inPreSave">
              <div v-if="!saving">
                <div class="error" v-if="error">{{ error }}</div>
                Är du säker på att du vill ändra måltalen?
                <div class="pre-save-buttons">
                  <button type="submit" class="button pre-save-confirm" @click="save"><i class="bi bi-save"></i> Ja, spara</button>
                  <button type="button" class="button" @click="cancel"><i class="bi bi-x-lg"></i> Nej, ångra</button>
                </div>
              </div>
              <div class="saving" v-if="saving">
                <Loader />
              </div>
            </div>
          </div>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script>
  import api from '../api.js'
  import Loader from './Loader.vue'

  export default {
    name: 'TargetsEditor',
    props: {
      subject: Object,
      company: Object,
      show: Boolean
    },
    data() {
      return {
        inPreSave: false,
        saving: false,
        error: null,
        targets: null
      }
    },
    components: {
      Loader
    },
    mounted() {
      // make a new object so that we're not directly editing the source object
      this.targets = {
        window: this.subject.targets.window,
        count: {
          positive: this.subject.targets.count.positive,
          neutral: this.subject.targets.count.neutral
        },
        interval: {
          positive: this.subject.targets.interval.positive,
          neutral: this.subject.targets.interval.neutral
        }
      }
    },
    methods: {
      cancel() {
        if (!this.saving) {
          this.$emit('done')
        }
      },
      preSave() {
        this.inPreSave = true
      },
      async save() {
        this.saving = true
        this.errorMessage = null
        let res = await api.send('PATCH', `/subjects/${this.subject.id}/targets`, { targets: this.targets })
        this.saving = false

        if (res.success) {
          this.cancel()
        } else {
          this.error = res.errorMessage
        }
      }
    },
    watch: {
      show(val) {
        // leave "pre-save" mode if the modal is closed, so that we don't start there the next time the modal is opened
        if (!val) {
          this.inPreSave = false
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .cover {
    background-color: rgba(black, 0.75);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }

  .modal {
    background-color: #333333;
    max-width: 400px;
    max-height: 100vh;
    width: 100%;
    padding: 10px;
    overflow-y: auto;
  }

  .header {
    display: flex;
    align-items: center;

    &-logo {
      flex: 0;
      margin-right: 10px;
    }

    &-title {
      flex: 1;
      font-size: 14pt;
      font-weight: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: normal;
      white-space: nowrap;
    }
  }

  .body {
    margin-top: 10px;
  }

  .label {
    display: block;
    width: 100%;
    margin: 0 5px 10px 5px;
    color: #aaaaaa;
  }

  .fieldset {
    margin-bottom: 10px;
    border: #ffffff solid 1px;
    border-radius: 5px;
    display: flex;
  }

  .targets-input {
    display: block;
    width: 100%;
    background-color: #555555;
    border: #888888 solid 1px;
    padding: 3px 6px;
    outline: none;
    -moz-appearance: textfield;
    color: #aaaaaa;

    &:focus {
      outline: none;
    }

    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .buttons {
    .button {
      margin: 0 2px;
    }
  }

  .pre-save {
    text-align: center;

    &-buttons {
      margin-top: 10px;
    }

    &-confirm {
      margin-right: 10px;
    }
  }

  .saving {
    display: flex;
    justify-content: center;
  }

  .error {
    border: #da5c5c solid 1px;
    padding: 5px;
    margin-bottom: 10px;
  }
</style>
