import api from '../api.js'

export default {
  state() {
    return {
      companies: [],
      loading: true,
      error: false
    }
  },
  mutations: {
    setCompanies(context, payload) {
      if (payload.companies !== undefined) {
        context.companies = payload.companies
      }

      if (payload.loading !== undefined) {
        context.loading = payload.loading
      }
      
      if (payload.error !== undefined) {
        context.error = payload.error
      }
    }
  },
  actions: {
    async loadCompanies(context) {
      let res = await api.send('GET', '/companies')

      if (res.success) {
        context.commit('setCompanies', { companies: res.result, loading: false })
      } else {
        context.commit('setCompanies', { error: res.error, loading: false })
      }
    }
  }
}
