export default {
  state() {
    return {
      subjects: []
    }
  },
  mutations: {
    setSubjects(state, payload) {
      if (payload.subjects !== undefined) {
        state.subjects = payload.subjects
      }
    },
    updateSubjectStats(state, payload) {
      for (var i=0; i<state.subjects.length; i++) {
        if (state.subjects[i].id === payload.stats.subjectId) {
          state.subjects[i].averageInterval = payload.stats.averageInterval
          state.subjects[i].averageCount = payload.stats.averageCount
          state.subjects[i].lastEvent = payload.stats.lastEvent
          break;
        }
      }
    },
    updateSubject(state, payload) {
      for (var i=0; i<state.subjects.length; i++) {
        if (state.subjects[i].id === payload.subject.id) {
          state.subjects[i] = payload.subject
          break;
        }
      }
    }
  }
}
