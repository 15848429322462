<template>
  <canvas :style="{ width: `${size}px`, height: `${size}px` }"></canvas>
</template>

<script>
  export default {
    name: 'LowPoly',
    props: {
      size: Number,
      complexity: Number,
      randomness: Number
    },
    mounted() {
      this.create()
    },
    watch: {
      '$props': {
        deep: true,
        handler() {
          this.create()
        }
      }
    },
    methods: {
      create() {
        this.$el.width = this.size * window.devicePixelRatio
        this.$el.height = this.size * window.devicePixelRatio

        let ctx = this.$el.getContext('2d')

        let spacing = (this.size * window.devicePixelRatio) / this.complexity
        let points = []

        for (let y=0; y<this.complexity+3; y++) {
          points.push([])
          for (let x=0; x<this.complexity+3; x++) {
            points[y].push([
              (x - 1) * spacing + (Math.random() - 0.5) * spacing * this.randomness,
              (y - 1) * spacing + (Math.random() - 0.5) * spacing * this.randomness
            ])
          }
        }

        for (let y=0; y<points.length-1; y++) {
          for (let x=0; x<points.length-1; x++) {
            ctx.beginPath();
            ctx.moveTo(points[y][x][0], points[y][x][1]);
            ctx.lineTo(points[y][x+1][0], points[y][x+1][1]);
            ctx.lineTo(points[y+1][x+1][0], points[y+1][x+1][1]);
            ctx.lineTo(points[y+1][x][0], points[y+1][x][1]);
            ctx.lineTo(points[y][x][0], points[y][x][1]);
            
            ctx.fillStyle = `rgba(255, 255, 255, ${(Math.random()*0.25).toFixed(3)})`;
            ctx.fill();
          }
        }
      }
    }
  }
</script>
