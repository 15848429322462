import store from './store'

function endpoint() {
  switch (window.location.hostname) {
    case '127.0.0.1':
    case 'localhost':
      return [
        'http://127.0.0.1:5000/api',
        'ws://127.0.0.1:5000/admin'
      ]
      
    case 'dev.effektivitet.prs.se':
      return [
        'https://prs-cdp-dev-efficiency-webapp.azurewebsites.net/api',
        'wss://prs-cdp-dev-efficiency-webapp.azurewebsites.net/admin'
      ]
      
    case 'effektivitet.prs.se':
      return [
        'https://prs-cdp-prod-efficiency-webapp.azurewebsites.net/api',
        'wss://prs-cdp-prod-efficiency-webapp.azurewebsites.net/admin'
      ]
  }
}

async function send(method, path, data) { 
  let options = {
    method: method,
    headers: {}
  }

  if (data) {
    options.body = JSON.stringify(data)
    options.headers['Content-Type'] = 'application/json'
    console.log(options.data)
  }

  let adminToken = window.localStorage.getItem('admin')
  if (adminToken) {
    options.headers['X-Admin'] = adminToken
  }

  try {
    let res = await fetch(endpoint()[0] + path, options)
    let json = await res.json()

    if (json.statusCode === 403) {
      store.dispatch('logout')
    }

    return json
  } catch (err) {
    console.log(err)
    return {
      success: false,
      statusCode: null,
      result: null,
      errorMessage: err.message
    }
  }
}

export default {
  endpoint,
  send
}
