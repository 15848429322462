<template>
  <div class="subject">
    <TargetsEditor :subject="subject" :company="company" :show="targetsEditing" @done="doneEditingTargets()" />
    <div class="summary" @click="editTargets()">
      <div class="left">
        <h1 class="name">{{ subject.name }}</h1>
        <p class="stat"><span class="bi bi-stopwatch-fill"></span> {{ averageInterval }} / {{ targetInterval }}</p>
        <p class="stat"><span class="bi bi-people-fill"></span> {{ averageCount }} / {{ targetCount }}</p>
      </div>
      <div class="right">
        <div class="efficiency">
          <div class="efficiency-outer">
            <div class="efficiency-inner">
              <p class="efficiency-value">{{ efficiency }}%</p>
              <p class="efficiency-title">effektivitet</p>
            </div>
          </div>
          <LowPoly class="efficiency-lowpoly" :size="65" :complexity="4" :randomness="0.5" :style="{ 'background-color': efficiencyColor }" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import LowPoly from './LowPoly.vue'
  import TargetsEditor from './TargetsEditor.vue'

  export default {
    name: 'Subject',
    props: {
      subject: Object,
      company: Object
    },
    data() {
      return {
        targetsEditing: false
      }
    },
    components: {
      LowPoly,
      TargetsEditor
    },
    computed: {
      averageInterval() {
        return this.formatInterval(this.subject.averageInterval)
      },
      targetInterval() {
        return this.formatInterval(this.subject.targets.interval.positive)
      },
      averageCount() {
        return this.formatCount(this.subject.averageCount)
      },
      targetCount() {
        return this.formatCount(this.subject.targets.count.positive)
      },
      efficiency() {
        let avg = (this.subject.targets.window / this.subject.averageInterval) * this.subject.averageCount
        let target = (this.subject.targets.window / this.subject.targets.interval.positive) * this.subject.targets.count.positive
        let efficiency = avg / target

        if (isNaN(efficiency) || !isFinite(efficiency)) {
          return 0
        } else {
          return Math.round(efficiency * 100)
        }
      },
      efficiencyColor() {
        if (this.efficiency === 0) {
          return '#222222'
        } else {
          return `hsl(${(Math.min(100, Math.max(70, this.efficiency)) - 70) * 3.5}, 60%, 30%)`
        }
      }
    },
    watch: {
      'subject.lastEvent': {
        deep: false,
        handler() {
          this.$el.classList.remove('flash')
          setTimeout(() => this.$el.classList.add('flash'), 0)
        }
      }
    },
    methods: {
      formatInterval(s) {
        let totalSeconds = Math.floor(s)

        if (totalSeconds >= 3600) {
          return '1h+'
        }

        let minutes = Math.floor(totalSeconds / 60)
        let seconds = totalSeconds - minutes * 60
        
        return ('0' + minutes).substr(-2) + ':' + ('0' + seconds).substr(-2)
      },
      formatCount(c) {
        return (Math.round(c * 10) / 10).toString().replace('.', ',')
      },
      editTargets() {
        this.targetsEditing = true
      },
      doneEditingTargets() {
        this.targetsEditing = false
      }
    }
  }
</script>

<style lang="scss" scoped>
  .subject {
    background-color: #111111;

    &.flash {
      animation: subjectflash 1s linear 0s 1 normal forwards running;
    }
  }

  .summary {
    display: flex;
    padding: 10px;
    margin-top: 10px;
  }

  .left {
    width: 100px;
    flex: 1 1;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .right {
    flex: 0 1 auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .efficiency {
    width: 65px;
    height: 65px;
  }

  .efficiency-lowpoly {
    border-radius: 100%;
    z-index: 0;
  }

  .efficiency-outer {
    width: 0;
    height: 0;
    z-index: 1;
  }

  .efficiency-inner {
    width: 65px;
    height: 65px;
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 10pt;
    text-shadow: rgba(#000000, 0.75) 1px 1px 0;
    z-index: inherit;

    .efficiency-value {
      font-size: 14pt;
      line-height: 1.2em;
      z-index: inherit;
    }

    .efficiency-title {
      font-size: 8pt;
      line-height: 1em;
      opacity: 0.8;
     z-index: inherit;
    }
  }

  .name {
    width: 100%;
    font-weight: 400;
    font-size: 14pt;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .stats {
    color: #aaaaaa;
    display: block;
    text-align: left;
    line-height: 1em;
  }

  .stat {
    color: #aaaaaa;
    font-size: 11pt;
  }

  @keyframes subjectflash {
    from {
      background-color: #444444;
    }

    to {
      background-color: #111111;
    }
  }
</style>
