import { createStore } from 'vuex'
import auth from './auth.js'
import companies from './companies.js'
import subjects from './subjects.js'
import events from './events.js'
import devices from './devices.js'

export default createStore({
  modules: {
    auth,
    companies,
    subjects,
    events,
    devices
  }
})
