import store from './store'
import api from './api.js'

let closed = true;
let socket;

function connect() {
  disconnect()
  closed = false
  socket = new WebSocket(api.endpoint()[1])
  socket.addEventListener('close', onClose)
  socket.addEventListener('message', onMessage)
  socket.addEventListener('open', onOpen)
  socket.addEventListener('error', onError)
}

function disconnect() {
  closed = true
  if (socket) {
    try {
      socket.close()
    } catch (err) {
      console.log(err)
    }
    socket = null
  }
}

function onMessage(event) {
  let spacePos = event.data.indexOf(' ')

  if (spacePos === -1) {
    return
  }

  let messageType = event.data.substr(0, spacePos)
  let messageBody = JSON.parse(event.data.substr(spacePos + 1))

  switch (messageType) {
    case 'Subjects':
      store.commit('setSubjects', { subjects: messageBody })
      break;
    case 'Stats':
      store.commit('updateSubjectStats', { stats: messageBody })
      break;
    case 'SubjectChange':
      store.commit('updateSubject', { subject: messageBody })
      break;
    case 'Devices':
      store.commit('setDevices', { devices: messageBody })
      break;
    case 'UpdateDevice':
      store.commit('updateDevice', { device: messageBody })
      break;
  }
}

function onClose() {
  // if this close wasn't intended, reconnect
  if (!closed) {
    connect()
  }
}

function onOpen() {
  socket.send(window.localStorage.getItem('admin'))
}

function onError(event) {
  console.log('ws error', event)
}

export default {
  connect,
  disconnect
}
