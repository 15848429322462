import api from '../api.js'

export default {
  state() {
    return {
      loggedIn: false,
      loading: false,
      error: null
    }
  },
  mutations: {
    setAuth(state, payload) {
      if (payload.loggedIn !== undefined) {
        state.loggedIn = payload.loggedIn
      }

      if (payload.loading !== undefined) {
        state.loading = payload.loading
      }

      if (payload.error !== undefined) {
        state.error = payload.error
      }
    },
    logout(state) {
      state.loggedIn = false
    }
  },
  actions: {
    restoreSession(context) {
      let token = window.localStorage.getItem('admin')
      context.commit('setAuth', { loggedIn: token !== null, loading: false, error: null })
    },
    async login(context, payload) {
      context.commit('setAuth', { loggedIn: false, loading: true, error: null })
      
      try {
        let res = await fetch(`${api.endpoint()[0]}/login`, {
          method: 'POST',
          body: JSON.stringify({ password: payload.password }),
          headers: {
            'Content-Type': 'application/json'
          }
        });
        
        let json = await res.json()

        if (json.success) {
          window.localStorage.setItem('admin', json.result.token)
          context.commit('setAuth', { loggedIn: true, loading: false, error: null })
        } else {
          context.commit('setAuth', { loggedIn: false, loading: false, error: json.error })
        }
      } catch (err) {
        context.commit('setAuth', { loggedIn: false, loading: false, error: err.message })
        console.log(err)
      }
    },
    logout(context) {
      window.localStorage.removeItem('admin')
      context.commit('logout')
    }
  }
}
